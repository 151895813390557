import { Controller } from '@hotwired/stimulus';

const ModelTypes = {
  companyWide: 'company_wide',
};

const CompanyContributionTypes = {
  fixed: 'fixed',
  match: 'match',
  stepRate: 'step_rate',
};

class CompanyProvidentFundSettingController extends Controller {
  static targets = ['isEnabled', 'companyWideModel', 'fixedCompanyContributionType'];

  connect() {
    this.setEnabledState();
  }

  setEnabledState() {
    const isEnabled = this.isEnabledTarget.checked;

    // Filter out hidden inputs, checkboxes, and submit buttons, inputs that are nested inside hidden elements
    const inputs = Array.from(
      this.element.querySelectorAll(
        'input:not([type="checkbox"], [type="submit"], [type="hidden"]), select'
      )
    );
    const availableInputs = this._filterNestedHiddenInputs(inputs, this.element);

    availableInputs.forEach((input) => {
      input.disabled = !isEnabled;
    });

    this._disablePercentageBoxes(isEnabled);
  }

  handleSelectModelType(event) {
    const isHidden = event.target.value !== ModelTypes.companyWide;

    this.companyWideModelTarget.hidden = isHidden;

    const inputsAndSelects = this.companyWideModelTarget.querySelectorAll('input, select');

    inputsAndSelects.forEach((element) => {
      element.disabled = isHidden;
    });
  }

  handleSelectCompanyContributionType(event) {
    const isHidden = event.target.value !== CompanyContributionTypes.fixed;

    this.fixedCompanyContributionTypeTarget.hidden = isHidden;

    const inputsAndSelects =
      this.fixedCompanyContributionTypeTarget.querySelectorAll('input, select');

    inputsAndSelects.forEach((element) => {
      element.disabled = isHidden;
    });
  }

  _disablePercentageBoxes(isEnabled) {
    const percentageBoxes = this.element.querySelectorAll('.percentage-box');

    percentageBoxes.forEach((box) => {
      if (isEnabled) {
        box.classList.remove('is-disabled');
      } else {
        box.classList.add('is-disabled');
      }
    });
  }

  _filterNestedHiddenInputs(inputs, outermostElement) {
    return inputs.filter((input) => {
      let element = input;

      while (element && element !== outermostElement) {
        if (element.hidden === true) {
          return false;
        }
        element = element.parentNode;
      }

      return true;
    });
  }
}

export default CompanyProvidentFundSettingController;
