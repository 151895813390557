import { Controller } from '@hotwired/stimulus';

import CycleDate from '../utils/cycle_date';

class PayrollSettingFormController extends Controller {
  static targets = [
    'payrollFirstCycleStartDate',
    'payrollFirstCycleEndDate',
    'payrollFirstCycleAttendanceStartDate',
    'payrollFirstCycleAttendanceEndDate',
    'payrollSecondCycleStartDate',
    'payrollSecondCycleEndDate',
    'payrollSecondCycleAttendanceStartDate',
    'payrollSecondCycleAttendanceEndDate',
    'employmentType',
  ];

  connect() {
    this.updateCycleEndDates();
  }

  updateCycleEndDates() {
    if (this._isDailyEmployment()) {
      const firstCycleStartDate = this.payrollFirstCycleStartDateTarget.value;
      const secondCycleStartDate = this.payrollSecondCycleStartDateTarget.value;

      const firstCycleAttendanceStartDate = this.payrollFirstCycleAttendanceStartDateTarget.value;
      const secondCycleAttendanceStartDate = this.payrollSecondCycleAttendanceStartDateTarget.value;

      const firstCycleEndDate = this._firstCycleEndDateForDailyPayment(secondCycleStartDate);
      this.payrollFirstCycleEndDateTarget.value = firstCycleEndDate;

      const secondCycleEndDate = this._secondCycleEndDateForDailyPayment(firstCycleStartDate);
      this.payrollSecondCycleEndDateTarget.value = secondCycleEndDate;

      const firstCycleAttendanceEndDate = this._firstCycleAttendanceEndDateForDailyPayment(
        secondCycleAttendanceStartDate
      );
      this.payrollFirstCycleAttendanceEndDateTarget.value = firstCycleAttendanceEndDate;

      const secondCycleAttendanceEndDate = this._secondCycleAttendanceEndDateForDailyPayment(
        firstCycleAttendanceStartDate
      );
      this.payrollSecondCycleAttendanceEndDateTarget.value = secondCycleAttendanceEndDate;
    } else {
      const firstCycleStartDate = this.payrollFirstCycleStartDateTarget.value;
      const firstCycleAttendanceStartDate = this.payrollFirstCycleAttendanceStartDateTarget.value;

      const firstCycleEndDate = this._firstCycleEndDate(firstCycleStartDate);
      const firstCycleAttendanceEndDate = this._firstCycleAttendanceEndDate(
        firstCycleAttendanceStartDate
      );

      this.payrollFirstCycleEndDateTarget.value = firstCycleEndDate;
      this.payrollFirstCycleAttendanceEndDateTarget.value = firstCycleAttendanceEndDate;
    }
  }

  _firstCycleEndDate(firstCycleStartDate) {
    return CycleDate.newFromString(firstCycleStartDate)
      .calculateCycleEndDate()
      .stringifiedCycleDate();
  }

  _firstCycleAttendanceEndDate(firstCycleAttendanceStartDate) {
    return CycleDate.newFromString(firstCycleAttendanceStartDate)
      .calculateCycleEndDate()
      .stringifiedCycleDate();
  }

  _firstCycleEndDateForDailyPayment(secondCycleStartDate) {
    return CycleDate.newFromString(secondCycleStartDate)
      .calculateCycleEndDate(-1)
      .stringifiedCycleDate();
  }

  _secondCycleEndDateForDailyPayment(firstCycleStartDate) {
    return CycleDate.newFromString(firstCycleStartDate)
      .calculateCycleEndDate()
      .stringifiedCycleDate();
  }

  _firstCycleAttendanceEndDateForDailyPayment(secondCycleAttendanceStartDate) {
    return CycleDate.newFromString(secondCycleAttendanceStartDate)
      .calculateCycleEndDate(-1)
      .stringifiedCycleDate();
  }

  _secondCycleAttendanceEndDateForDailyPayment(firstCycleAttendanceStartDate) {
    return CycleDate.newFromString(firstCycleAttendanceStartDate)
      .calculateCycleEndDate()
      .stringifiedCycleDate();
  }

  _isDailyEmployment() {
    return this.employmentTypeTarget.value === 'Daily';
  }
}

export default PayrollSettingFormController;
