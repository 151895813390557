import { Controller } from '@hotwired/stimulus';

export default class ExemptionTypeController extends Controller {
  static targets = ['percentageField'];

  connect() {
    // console.log("ExemptionTypeController connected");
  }

  togglePercentage(event) {
    const selectedValue = event.target.value;

    if (selectedValue === 'Percentage') {
      this.percentageFieldTarget.hidden = false;
      this.percentageFieldTarget.querySelector('input').required = true;
    } else {
      this.percentageFieldTarget.hidden = true;
      this.percentageFieldTarget.querySelector('input').required = false;
      const inputField = this.percentageFieldTarget.querySelector('input');
      inputField.value = '';
    }
  }
}
