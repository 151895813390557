import NestedForm from 'stimulus-rails-nested-form';

class ContributionStepRateNestedFormController extends NestedForm {
  static targets = ['index'];

  connect() {
    super.connect();
    this.updateIndex();
    this.updateLastRow();

    // Add default row if none exist
    if (this.index === 0) {
      this.add(new Event('connect'));
    }
  }

  add(event) {
    super.add(event);
    if (this.lastIndexTarget) {
      this.lastIndexTarget.innerHTML = ++this.index;
    }
    this.updateLastRow();
  }

  remove(event) {
    event.preventDefault();

    const wrapper = event.target.closest(this.wrapperSelectorValue);
    if (!wrapper) {
      console.error('Wrapper not found for remove action.');
      return;
    }

    if (document.querySelectorAll(this.wrapperSelectorValue).length <= 1) {
      console.warn('Cannot remove the last remaining field.');
      return;
    }

    this.index--;
    const previousSelect = wrapper.previousElementSibling?.querySelector(
      this.selectMaxWorkedDurationClass
    );

    wrapper.remove();
    previousSelect?.removeAttribute('disabled');

    this.updateLastRow();
  }

  updateIndex() {
    this.index =
      this.indexTargets.length > 0
        ? parseInt(this.indexTargets[this.indexTargets.length - 1].innerHTML)
        : 0;
  }

  updateLastRow() {
    // Select all form wrappers
    const wrappers = document.querySelectorAll(this.wrapperSelectorValue);

    wrappers.forEach((wrapper, index) => {
      const maxWorkedDurationSelect = wrapper.querySelector(this.selectMaxWorkedDurationClass);
      if (maxWorkedDurationSelect) {
        // Disable all but the last row
        maxWorkedDurationSelect.disabled = index !== wrappers.length - 1;
      }
    });
  }

  get lastIndexTarget() {
    return this.indexTargets[this.indexTargets.length - 1];
  }

  get selectMaxWorkedDurationClass() {
    return '.form__select-max-worked-duration';
  }
}

export default ContributionStepRateNestedFormController;
